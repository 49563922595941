<template>
  <v-form
    ref="form"
    @submit.prevent="deleteProfile"
  >
    <v-alert
      v-for="error in errorMessages"
      :key="error"
      type="error"
    >
      {{ error }}
    </v-alert>
    <v-card :loading="submitting">
      <v-card-title>
        Delete Account
      </v-card-title>
      <v-card-text v-if="!success">
        <p>Deleting your account will irrevocably destroy all records of your participation in Quest.</p>
        <p>To continue, please confirm your password below.</p>
        <v-text-field
          v-model="password"
          :disabled="submitting"
          :rules="confirmPasswordRules"
          required
          type="password"
          label="Password"
        />
      </v-card-text>
      <v-card-actions v-if="!success">
        <v-btn
          color="error"
          :disabled="submitting"
          type="submit"
        >
          Permanently Delete My Account
        </v-btn>
      </v-card-actions>
      <v-card-text v-if="success">
        <p>Your account was successfully deleted.</p>
      </v-card-text>
    </v-card>
  </v-form>
</template>

<script>
import { allianceAPI } from '@/api'
import { parseResponseErrors } from '@/utils/errors'

export default {
  data() {
    return {
      password: '',
      submitting: false,
      success: null,
      errorMessages: null,
      confirmPasswordRules: [
        v => !!v || 'Field is required',
      ],
    }
  },
  methods: {
    deleteProfile: function () {
      if (!this.$refs.form.validate()) {
        return
      }
      this.submitting = true
      this.success = null
      this.errorMessages = null
      const data = {
        password: this.password,
      }
      console.log(this.password)
      console.log(data)
      return allianceAPI.delete('/alliance/profile/me/', { data: data })
        .then(() => {
          this.success = true
          this.password = ''
          // Logout the user.
          this.$store.dispatch('auth/logout')
        })
        .catch((error) => {
          this.errorMessages = parseResponseErrors(error)
          this.success = false
        })
        .finally(() => this.submitting = false)
    }
  },
}
</script>
